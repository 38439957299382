import { Button } from "@mui/material"
import { format, isValid } from "date-fns"
import React from "react"
import { store } from "../../../stores/Store"


export default function QuestionnaireDetails ({questionnaire, showDetails = true, height = "300px"}) {

    const {isHidden, getFieldValue, getPageList, formatPlaceholders} = store.QuestionnaireStore

    var template = JSON.parse(questionnaire.json)
    var answers = JSON.parse(questionnaire.answers)

    var pagesSubmitted = getPageList(template.pages, answers, true)
    var pagesRemaining = getPageList(template.pages, answers, false)
    var updatedAt = new Date(questionnaire.updatedAt)
    var updatedAtDisplay = isValid(updatedAt) ? format(updatedAt, "yyyy-MM-dd HH:mm") : updatedAt.toString()

    return (
        <div className="questionnaire-details">

            {showDetails ? (
                <div style={{display: "grid", gap: "8px"}}>
                    <div>
                        <p style={{margin: "0"}}>{questionnaire.displayName ? questionnaire.displayName : questionnaire.uid}</p>
                        
                        <a 
                            className="link" 
                            rel="noreferrer"
                            target="_blank"
                            style={{fontSize: "12px"}}
                            href={questionnaire.link} >
                            {questionnaire.link}
                        </a>
                    </div>

                    <div style={{display: "grid", gridTemplateColumns: "100px auto", fontSize: "12px", gap: "5px"}}> 
                        <span>Questionnaire:</span>
                        <span>{questionnaire.template.name} <span style={{color: "lightgrey", marginLeft: "10px"}}>{questionnaire.template.key}</span></span>
                        <span>Last updated:</span>
                        <span>{updatedAtDisplay} ({pagesSubmitted?.length} of {pagesSubmitted?.length + pagesRemaining?.length} pages completed)</span>
                        <span>Access code:</span>
                        <span style={{display: "flex", alignItems: "center", gap: "10px", fontFamily: "monospace", color: "#484848"}}>
                            <b>{questionnaire.accessCode}</b> 
                            <Button className="btn btn-xs" variant="contained" color="info" onClick={() => navigator.clipboard.writeText(questionnaire.accessCode)}>Copy access code</Button>
                        </span>
                    </div>
                </div>
            ) : null}

            {pagesSubmitted.length ? (
                <div className="questionnaire-pages" style={{maxHeight: height, display: "grid", overflow: "auto", border: "1.5px solid #cfcfcf", backgroundColor: "#f4f4f4", margin: "10px 0"}}>
                    {pagesSubmitted?.map((page, p) => (
                        <div key={p} style={{display: "grid", margin: "5px"}}>
                            <h5>{formatPlaceholders(page.title, answers)}</h5>

                            {page.fields?.filter(field => {
                                return !isHidden(field, answers)
                            }).map((field, f) => {
                                
                                return (
                                    <div key={f} style={{display: "grid", gridTemplateColumns: "50% 50%", margin: "5px 0 0 14px"}}>
                                        <small>{formatPlaceholders(field.label, answers)}</small>
                                        <small>{formatPlaceholders(getFieldValue(field, answers), answers)}</small>
                                    </div>
                                )
                            })}

                            {page.table ? (
                                <div style={{display: "grid", gridTemplateColumns: "50% 50%", margin: "5px 0 0 14px"}}>
                                    <small>{answers[page.table.list_name]?.length} Entries</small>
                                </div>
                            ) : null}
                        </div>   
                    ))}
                </div>
            ) : null}
        </div>
    )
}