import React from "react";
import { store } from "../../../stores/Store";

export default function QuestionLog () {

    const ref = React.useRef(null)

    React.useEffect(() => {
        if (ref.current) {
            ref.current.scrollTo(0, ref.current.scrollHeight)
        }
    }, [])

    return (
        <div className="question-log">
            <p>Answers provided from Questionnaire</p>
            <div className="question-log-content" ref={ref}>
                {store.QuestionnaireStore.interceptedQuestions?.map((ir, i) => (
                    <div>
                        <p className="message">{ir.question}</p>
                        <p className="message answer">{ir.answer}</p>
                    </div>
                ))}
            </div>
        </div>
    )
}