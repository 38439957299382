import React from "react"
import * as Yup from 'yup'
import { Button } from "@mui/material"
import { onlineForms } from "../../../misc/Online Forms"
import { useFormik } from "formik"
import { store } from "../../../stores/Store"
import Field from "../fields/Field"


export default function AutomationConfirmFormSection ({
    formId,
    questionnaire,
    additionalData,
    hidden = true,
    onCancel = () => {},
    onSubmit = (values) => {}
}) {

    var form = onlineForms.find(f => f.id === formId)
    var questionnaireDisplayName = questionnaire
        ? `${questionnaire.template.name}, ${questionnaire.displayName ? questionnaire.displayName : questionnaire.uid}`
        : "None selected"
    
    var formik = useFormik({
        initialValues: {
            advisor_email: store.AppStore.sessionInfo.userEmail,
        },
        validationSchema: Yup.object({
            advisor_email: Yup.string().matches(/\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/, "A valid email is required").required("This field is required"),
        }),
        onSubmit: (values) => {
            onSubmit(values)
        }
    })

    if (hidden) return <></>

    return (
        <form className="form" onSubmit={formik.handleSubmit}>
            <div className="form-fields">
                <p>{form.name}</p>

                <div style={{maxWidth: "360px"}}>
                    <Field.Text
                        formik
                        label="Advisor email"
                        helpText="Correspondence with the Home Office will go via the email address entered here."
                        name="advisor_email"
                        value={formik.values.advisor_email}
                        warning={formik.submitCount && formik.errors.advisor_email ? formik.errors.advisor_email : false}
                        onChange={formik.handleChange}  />
                </div>
                
                <div>

                    <div className="help-text" style={{display: "grid", gridTemplateColumns: "220px auto"}}>
                        <span>Selected Questionnaire</span>
                        <span>{questionnaireDisplayName}</span>

                        {additionalData?.id_check_document_type ? (
                            <>
                                <span>ID check document type</span>
                                <span>{additionalData.id_check_document_type}</span>
                                <span>ID check document number</span>
                                <span>{additionalData.id_check_document_number}</span>
                                <span>ID check document date of birth</span>
                                <span>{additionalData.id_check_document_date_of_birth}</span>
                            </>
                        ) : null}
                    </div>
                </div>
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" onClick={() => onCancel()}>Back</Button>
                <Button className="btn btn-sm" type="submit" color="success" variant="contained">
                    Confirm and start automation
                </Button>
            </div>
        </form>
    )
}