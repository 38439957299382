import { makeAutoObservable } from 'mobx'
import Ajax from '../Ajax'
import { Alert, AlertModal } from '..'
import QuestionLog from '../components/views/main/QuestionLog'


export class QuestionnaireStore {
    
    interceptedQuestions: any[] = [];
    loading: boolean = true;
    templates: any[] | null = null;
    questionnaire: any = null;
    questionnaires: any[] | null = null;
    currentQuestionnaireId: string | null = null;
    alert: any = null;

    order: "asc" | "desc" = "desc"
    orderedBy: string = "createdAt"
    searchString: string = ""

    constructor() {
        makeAutoObservable(this)
    }

    setOrder = (order) => {
        this.order = order
    }

    setOrderedBy = (orderedBy) => {
        this.orderedBy = orderedBy
    }

    setSearchString = (str) => {
        this.searchString = str
    }

    loadQuestionnaire = async (caseUid, questionnaireUid) => {
        if (questionnaireUid !== this.currentQuestionnaireId) {
            this.interceptedQuestions = []
        }
        await Ajax.Enquiry.CreateWithQuestionnaire(caseUid, questionnaireUid).catch((response) => {  
            Alert({message: response.data?.detail})
        })
    }

    handleInformationRequestIntercepted = (iri: any) => {

        this.interceptedQuestions.push(iri)

        if (this.alert) {
            this.alert.dismiss()
        }

        this.alert = Alert({
            persist: true,
            color: "success", 
            horizontalAnchor: "center", 
            message: `${this.interceptedQuestions.length} answers provided by questionnaire`, 
            actions: [{ label: "See answers", color: "info", action: () => AlertModal({body: <QuestionLog />, width: "400px"}) }]
        })
    }

    getQuestionnaires = async () => {
        this.loading = true

        await Ajax.Questionnaire.All().then((response) => {
            this.questionnaires = response.data
        }).catch(() => {
            this.questionnaires = []
        })

        this.loading = false
    }

    getTemplates = async () => {
        await Ajax.Questionnaire.Templates().then((response) => {
            this.templates = response.data
        }).catch(() => {
            this.templates = []
        })

        this.loading = false
    }

    filterQuestionnaires = (questionnaires) => {
        if (!Array.isArray(questionnaires) || !questionnaires.length){
            return []
        }

        questionnaires = [...questionnaires].sort((a, b) => {

            var aValue = null
            var bValue = null

            if (this.orderedBy === "template") {
                aValue = a.template?.name
                bValue = b.template?.name
            } else if (this.orderedBy === "respondent") {
                aValue = a.respondent.respondentEmail
                bValue = b.respondent.respondentEmail
            } else if (Object.keys(a).includes(this.orderedBy) && Object.keys(b).includes(this.orderedBy)) {
                aValue = a[this.orderedBy]
                bValue = b[this.orderedBy]
            } else {
                aValue = a.createdAt
                bValue = b.createdAt
            }
            
            return this.order === "desc" ? bValue?.localeCompare(aValue) : aValue?.localeCompare(bValue)
        })

        if (this.searchString) {
            questionnaires = questionnaires.filter(q => {
                return (
                    q.displayName?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    q.template?.name?.toLowerCase().includes(this.searchString.toLowerCase()) ||
                    q.respondent?.respondentEmail?.toLowerCase().includes(this.searchString.toLowerCase())
                )
            })
        }

        return questionnaires
    }

    isHidden = (fieldOrPage, answers) => {

        var valuesToLookFor = {...fieldOrPage.dependsOn, ...fieldOrPage.dependsOnAny}

        var fieldsKeysSatisfied = Object.keys(valuesToLookFor).filter((key) => {
            var valueToLookFor = valuesToLookFor[key]
            return Array.isArray(valueToLookFor) ? valueToLookFor.includes(answers[key]) : valueToLookFor === answers[key]
        })

        if (fieldOrPage.dependsOn) {
            return Object.keys(valuesToLookFor).length !== fieldsKeysSatisfied.length
        }

        if (fieldOrPage.dependsOnAny) {
            return fieldsKeysSatisfied?.length === 0
        }

        return false
    }

    getFieldValue = (field, answers) => {
        var value = answers[field.name]

        if (field.options?.hasOwnProperty(value)) {
            value = field.options[value]
        }

        if (field.type === "checkbox") {
            value = value === "true" ? "Yes" : "No"
        }
        
        return value
    }

    formatPlaceholders = (message, answers) => {
        if (message) {
            var placeholders = message.matchAll("(?<={).+?(?=})", message)
            placeholders.forEach((placeholder) => {
                var value = answers[placeholder]
                if (value) {
                    message = message.replace(`{${placeholder}}`, value)
                }
            })
        }
        return message
    }

    getPageList = (pages, answers, submitted = true) => {
        var pageList = []

        const handlePages = (pages) => {
            pages.forEach(page => {
                
                var hide = this.isHidden(page, answers)

                if (!hide) {
                    pageList.push(page)
                }

                // Check for conditional pages
                page.fields?.forEach(field => {
                    var value = answers[field.name]
                    if (field.conditional_pages) {
                        if (value in field.conditional_pages) {
                            handlePages(field.conditional_pages[value])
                        } else if ("else" in field.conditional_pages) {
                            handlePages(field.conditional_pages["else"])
                        }
                    }
                })
            })
        }

        handlePages(pages)

        return pageList.filter(page => ((submitted && page.submitted) || (!submitted && !page.submitted)))
    }
}