import Field from "../../../form/fields/Field"
import { DisplayDictionary, DisplayValue } from "../../../.."
import TruncText from "../../../../misc/TruncText"
import AutomationOptions from "./AutomationOptions"
import { Button } from "@mui/material"
import React from "react"
import { Theme } from "../../../../misc/Theme"
import { store } from "../../../../stores/Store"


export default function AutomationDetail ({automationNode}) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    const valueStatuses = Object.keys(DisplayDictionary("automation_value_validity"))
    const [valueStatusFilter, setValueStatusFilter] = React.useState(valueStatuses)
    const printContainer = React.useRef(null)

    const print = () => {
        var WinPrint = window.open('#', '_blank', 'left=0,top=0,width=800,height=900,toolbar=0,scrollbars=0,status=0');
        
        if (printContainer?.current?.innerHTML) {
            var htmlHead = window.document.head.innerHTML
            htmlHead = htmlHead.replace("<script", "<!--<script")
            htmlHead = htmlHead.replace("</script>", "</script>-->")
            htmlHead = htmlHead.replace("<title", "<!--<title")
            htmlHead = htmlHead.replace("</title>", "</title>-->")
            
            WinPrint.document.write(`
                <html>
                    <head>
                        ${htmlHead}
                        <title>Automation Review</title>
                    </head>
                    <body>
                        <div class="auto-form-map-diagnostics">
                            ${printContainer.current.innerHTML}
                        </div>
                    </body>
                </html>
            `)
            WinPrint.document.close()
            WinPrint.focus()
            setTimeout(() => {
                WinPrint.print()
            }, 1000)
        }
    }

    // Don't show page if there are no fields to show after filtering is applied
    var submittedPages = outputData?.submitted_pages.filter((page, i) => {

        var fields = page.fields.filter(f => {
            return valueStatusFilter.includes(f.value_status) && f.name
        })

        return !!fields.length
    })

    var lastPage = submittedPages.length ? submittedPages[submittedPages.length - 1] : null

    return (
        <div className="form auto-form-map-diagnostics">
            <div className="header">
                <div className="header">
                    <h2>Automation Review</h2>
                    <Button className="btn btn-sm" variant="contained" color="success" onClick={() => print()}>Print PDF</Button>
                    
                    <AutomationOptions automationNode={automationNode} />
                </div>

                <div style={{width: "200px"}}>
                    <Field.MultipleSelect
                        checkboxes
                        name="field-filter"
                        label="Filter fields by validity of answer"
                        value={valueStatusFilter}
                        warning={false}
                        options={DisplayDictionary("automation_value_validity", true)} 
                        onChange={(e) => {
                            setValueStatusFilter(e.value)
                        }} />
                </div>
            </div>
            
            <div>
                {outputData?.validation_messages?.length ? (
                    <div style={{margin: "10px 0 0"}}>
                        <p>The automation got stuck on "{lastPage?.title}" page because of validation errors:</p>
                        {outputData?.validation_messages.map((message, i) => (
                            <small key={i} className="warning" style={{marginLeft: "20px", display: "list-item"}}>{message}</small>
                        ))}
                    </div>
                ) : null}

                {outputData.profile.questionnaires ? (
                    <div>
                        <p>Questionnaires used</p>
                        {outputData.profile.questionnaires.map((qRef, i) => (
                            <small key={i} style={{marginLeft: "20px", display: "list-item"}}>{qRef.label}</small>
                        ))}
                    </div>
                ) : null}

                <div>
                    <div className="diagnostics-field column-headers">
                        <span>Field name</span>
                        <span>Entered value</span>
                        <span>Validity</span>
                    </div>

                    <div style={{maxHeight: "520px", overflow: "auto"}}>
                        <div ref={printContainer}>
                            {submittedPages.map((page, i) => (
                                <div key={i} className="diagnostics-section">
                                    <h4>{i + 1}) {page.title}</h4>

                                    {page.fields.map((field, i) => <FieldReadOnly key={i} field={field} /> )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

function FieldReadOnly ({field}) {

    if (!field.name) return <></>

	var fieldStatus = ""
	var label = field.label
        ? field.label.split("\n")[0]
        : field.name.charAt(0).toUpperCase() + field.name.slice(1).replaceAll("_", " ")

    if (["true", "false"].includes(field.value)) {
        field.value = field.value.replace("true", "Yes").replace("false", "No")
    }

	if (field.value_status) {
		switch (field.value_status) {
            case "GUESS":
                fieldStatus = "guessed-value" // Amber
                break
            case "EMPTY":
			case "FALSE":
				fieldStatus = "false-value" // Red
				break
			case "GENUINE":
				fieldStatus = "genuine-value"
				break
			default:
				fieldStatus = ""
				break
		}
	}

    var value = field.value

    

	return (
		<div className="diagnostics-field">
			<span>{label}</span>
			<span><TruncText style={{color: "grey", fontWeight: "300"}} text={value} /></span>
			<span className={fieldStatus}>{DisplayValue("automation_value_validity", field.value_status)}</span>
		</div>
	)
}