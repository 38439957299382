import { format, isValid } from "date-fns"
import { store } from "../../../../stores/Store"
import Help from "../../../../misc/Help"
import TruncText from "../../../../misc/TruncText"
import { Button } from "@mui/material"
import { AlertModal } from "../../../.."
import ViewQuestionnaire from "../../questionnaire_mgmt/ViewQuestionnaire"

export default function EnquiryDataOriginsSection () {
    
    const {enquiry, getSubject} = store.EnquiryStore

    var origins_grouped = []

    enquiry.origins?.forEach((origin) => {
        var existing_item = origins_grouped.find((o) => origin.originUid === o.originUid)
        if (!existing_item) {
            origins_grouped.push({
                ...origin,
                insertedAt: undefined,
                insertedBy: undefined,
                usage: [
                    {date: origin.insertedAt, user: origin.insertedBy}
                ]
            })
        } else {
            existing_item.usage.push({date: origin.insertedAt, user: origin.insertedBy})
        }
    })

    return (
        <div className="detail-section">

            <div className="section-header">
                <div className="header-content">
                    <h4>Data origins</h4>
                    {/* {!sessionState ? <Button className="btn btn-xs" onClick={() => UpdateForm()}>Edit</Button> : null} */}
                </div>
            </div>

            {origins_grouped?.length ? (
                <div className="section-table">
                    {origins_grouped?.map((origin, i) => (
                        <div key={i} className="section-row">
                            {origin.description ? (
                                <div className="section-cell link" onClick={() => AlertModal({body: <ViewQuestionnaire uid={origin.originUid} />, width: "600px"})}>
                                    <TruncText text={origin.description} />
                                </div>
                            ) : null}
                            
                            {origin.label ? (
                                <div className="section-cell">
                                    <TruncText text={origin.label} />
                                </div>
                            ) : null}

                            <div className="section-cell" style={{minWidth: "110px", maxWidth: "110px"}}>
                                <Help content={`Used ${origin.usage.length} time${origin.usage.length !== 1 ? "s" : ""}`}>
                                    <Usage origin={origin} />
                                </Help>
                            </div>
                        </div>
                    ))}
                </div>
            ) : (
                <div className="section-header section-subheader">
                    <h5>No entries</h5>
                </div>
            )}
        </div>
    )
}


function Usage ({origin}) {


    var usage = origin.usage?.sort((a,b) => {
        return a.date?.localeCompare(b.date)
    })

    return (
        <div>
            <h3>Data from this origin was sourced {origin.usage.length} time{origin.usage.length !== 1 ? "s" : ""}</h3>
            <div style={{margin: "10px", display: "grid", gridTemplateColumns: "140px auto"}}>
                <small><b>Time</b></small>
                <small><b>User</b></small>
                {usage.map((usage, i) => {
                    var date = new Date(usage.date)
                    var formattedDate = isValid(date) ? format(date, "dd/MM/yyyy HH:mm") : "Invalid date"
                    return (
                        <>
                            <small>{formattedDate}</small>
                            <small>{usage.user ? usage.user : "Unknown user"}</small>
                        </>
                    )
                })}
            </div>
        </div>
    )
}