import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"
import { Alert } from "../../.."


interface FolderEditFormProps {
    folderNode: any;
}

interface FolderCreateFormProps {
    parentUid?: string;
    guidance?: string;
    omitTitle?: boolean;
    onSuccess?: Function;
}


export function FolderEditForm ({folderNode}: FolderEditFormProps) {
    return (
        <div>
            <h2>Edit Folder</h2>
            <FolderFormOnly
                initialValues={{
                    name: folderNode.name
                }}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={(values) => {
                    var folderWithNewName = {...folderNode, name: values.name}
                    Ajax.Node.Edit("folder", folderWithNewName).then((response) => {
                        store.AlertStore.Close()
                    }).catch((response) => {
                        Alert({message: response.data?.detail ? response.data?.detail : "Couldn't edit folder"})
                    })
                }} />
        </div>
    )
}

export function FolderCreateForm ({parentUid, guidance = null, omitTitle = false, onSuccess = (node) => {}}: FolderCreateFormProps) {

    return (
        <div>
            {!omitTitle ? <h2>Create Folder</h2> : null}

            <FolderFormOnly
                guidance={guidance}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={ async (folderNode) => {
                    await Ajax.Node.Create("folder", parentUid, folderNode).then(response => {
                        onSuccess(response.data)
                    }).catch((response) => {
                        Alert({message: response.data?.detail ? response.data?.detail : "Couldn't get owner"})
                    })
                }} />
        </div>
    )
}


export default function FolderFormOnly ({
    handleCancel,
    handleSubmit,
    guidance = null,
    initialValues = {
        "name": ""
    }
}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            name: Yup.string().required("You must prvide a name")
        }),

        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)

            await handleSubmit(values)
            setSubmitting(false)
        }
    })

    return (
        <form className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>
            <div className="form-fields">

                {guidance ? <p>{guidance}</p> : null}

                <Field.Text
                    formik
                    autoFocus
                    label="Folder name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    warning={formik.submitCount && formik.errors.name ? formik.errors.name : false}
                />
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    onClick={() => handleCancel()}
                    disabled={formik.isSubmitting}
                    className="btn btn-sm">
                    Cancel
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    disabled={formik.isSubmitting}
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>
        </form>
    )
}