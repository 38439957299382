import ErrorBoundary from "../../../../misc/ErrorBoundary";
import { store } from "../../../../stores/Store";
import Automation from "./Automation";

export default function AutomationSection () {
    
    var { enquiryAutomations } = store.AutomationStore

    if (!enquiryAutomations.length) return <></>

    return (
        <ErrorBoundary>
            <div className="detail-section">
                <div className="section-header">
                    <h4>Automations</h4>
                </div>

                <div className="section-subheader">
                    {enquiryAutomations.length ? <h5>Previous automations</h5> : <h5>No automations to show</h5>}
                </div>

                {enquiryAutomations.map((aNode, i) => <Automation key={i} automationNode={aNode} />)}
            </div>
        </ErrorBoundary>
    )
}