import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button, CircularProgress, LinearProgress } from "@mui/material"
import { store } from "../../../stores/Store"
import { User } from "../../../models/User"
import Account from "../../../models/Account"
import { Alert } from "../../.."


interface CaseEditFormProps {
    caseNode: any;
}

interface CaseCreateFormProps {
    parentUid?: string;
    guidance?: string;
    omitTitle?: boolean;
    onSuccess?: Function;
}

export function CaseEditForm ({caseNode}: CaseEditFormProps) {
    return (
        <div>
            <h2>Edit Case</h2>
            <CaseFormOnly
                initialValues={{
                    name: caseNode.name
                }}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={(values) => {
                    var caseWithNewName = {...caseNode, name: values.name}
                    Ajax.Node.Edit("case", caseWithNewName).then((response) => {
                        store.AlertStore.Close()
                    }).catch((response) => {
                        console.log("response", response)
                        Alert({message: response.data?.detail ? response.data?.detail : "Couldn't get owner"})
                    })
                }} />
        </div>
    )
}

export function CaseCreateForm ({parentUid, guidance = null, omitTitle = false, onSuccess = (node) => {}}: CaseCreateFormProps) {

    // const [ownerOptions, setOwnerOptions] = React.useState(null)

    // const getOwnerOptions = async () => {
    //     if (account) {
    //         if (account.childrenObjects) {
    //             setOwnerOptions(account.childrenObjects.map((user) => {
    //                 return {value: user.uid, label: user.label}
    //             }))
    //         } else {
    //             await Ajax.Node.Get(account.uid).then((response) => {
    //                 var users = response.data.childrenObjects.filter((child) => child.typeName.includes("UserNode"))
    //                 return users.map(user => {
    //                     return {label: user.label, value: user.uid}
    //                 })
    //             })
    //         }
    //     }
    //     return []
    // }

    // React.useEffect(() => {
    //     getOwnerOptions().then((users) => setOwnerOptions(users))
    // }, [])

    return (
        <div>
            {!omitTitle ? <h2>Create Case</h2> : null}

            <CaseFormOnly
                guidance={guidance}
                ownerOptions={null}
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={ async (caseNode) => {
                    await Ajax.Node.Create("case", parentUid, caseNode).then(response => {
                        onSuccess(response.data)
                    }).catch((response) => {
                        console.log("response", response)
                        Alert({message: response.data?.detail ? response.data?.detail : "Couldn't get owner"})
                    })
                }} />
        </div>
    )
}


export default function CaseFormOnly ({
    handleCancel,
    handleSubmit,
    ownerOptions = null,
    guidance = null,
    initialValues = {
        "name": ""
    }
}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            name: Yup.string().required("You must prvide a name")
        }),

        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)

            await handleSubmit(values)
            setSubmitting(false)
        }
    })

    return (
        <form className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>
            <div className="form-fields">

                {guidance ? <p>{guidance}</p> : null}

                <Field.Text
                    formik
                    autoFocus
                    label="Case name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    warning={formik.submitCount && formik.errors.name ? formik.errors.name : false}
                />

                {/* {ownerOptions && ownerOptions?.length ? (
                    <Field.Select
                        formik
                        label="Key contact"
                        name="ownerUid"
                        value={formik.values.ownerUid}
                        onChange={formik.handleChange}
                        warning={formik.submitCount ? formik.errors.ownerUid : ""}
                        options={ownerOptions}
                    />
                ) : null} */}
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    onClick={() => handleCancel()}
                    disabled={formik.isSubmitting}
                    className="btn btn-sm">
                    Cancel
                </Button>

                <Button
                    type="submit"
                    variant="contained"
                    disabled={formik.isSubmitting}
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>
        </form>
    )
}

