import React from "react"
import Ajax from "../../../../Ajax"
import { observer } from "mobx-react-lite"
import { Alert, AlertModal } from "../../../.."
import { store } from "../../../../stores/Store"
import { Button, CircularProgress } from "@mui/material"
import CreateEnquiryForm from "../../../form/enquiry/CreateEnquiryForm"
import IsAllowed from "../../../../models/Permissions"


interface EntityCtaProps {
    entity: any;
}

export default observer(function EntityCta ({entity}: EntityCtaProps) {

    const [loading, setLoading] = React.useState(false)

    const actionWrapper = async (action) => {
        setLoading(true)
        await action()
        setLoading(false)
    }

    if (loading) {
        return <CircularProgress style={{width: "14px", height: "14px", margin: 0}} />
    }

    if (!store.AppStore.sessionState) {
        return (
            <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
                {store.NodeStore.isEditing ? (
                    <>
                        <Button
                            disabled={true}
                            variant="contained" 
                            className="btn btn-sm">
                            Save
                        </Button>
                        <Button
                            color="info"
                            variant="contained" 
                            className="btn btn-sm"
                            onClick={() => { store.NodeStore.isEditing = false }} >
                            Cancel
                        </Button>
                    </>
                ) :(
                    <>
                        {entity.typeName === "Aora.Nationality.Data.PersonNode" && IsAllowed("EnquiryNode", "CREATE") && <Button
                            variant="contained" 
                            className="btn btn-sm"
                            onClick={() => AlertModal({body: <CreateEnquiryForm entityUid={entity.uid} hideStepsSection={window.innerWidth < 700} />, size: "auto"})} >
                            Create Enquiry
                        </Button>}
                        <Button
                            color="info"
                            variant="contained" 
                            className="btn btn-sm"
                            onClick={async () => { store.NodeStore.isEditing = true }} >
                            Edit {store.NodeStore.getDisplayType(entity)}
                        </Button>
                    </>
                )}
            </div>
        )
    }

    if (store.AppStore.sessionState?.includes("Editing " + entity.uid)) {
        return (
            <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
                <Button
                    variant="contained" 
                    className="btn btn-sm"
                    // disabled={!entity.editsPending || !!store.QuestionStore.informationRequest}
                    onClick={async () => actionWrapper(Ajax.Entity.SaveEdit().catch((response) => Alert({message: response.data.detail})))} >
                    Save
                </Button>
                <Button
                    className="btn btn-sm"
                    disabled={!!store.QuestionStore.informationRequest}
                    onClick={async () => actionWrapper(Ajax.Message.Cancel())} >
                    Cancel
                </Button>
            </div>
        )
    }

    return <></>
})