import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import ErrorBoundary from "../../../../../misc/ErrorBoundary";
import EnquiryCta from "./EnquiryCta";
import ReportCta from "./ReportCta";
import AutomationCta from "./AutomationCta";


export default observer(function NextStepsSection () {

    return (
        <ErrorBoundary>
            <div className={store.AppStore.sessionState ? "detail-section disabled" : "detail-section"}>
                <div className="section-header">
                    <div className="header-content" style={{justifyContent: "left", gap: "7px"}}>
                        <h4>Next steps</h4>
                    </div>
                </div>

                <div style={{display: "grid", gap: "10px"}}>
                    <EnquiryCta />
                    <ReportCta />
                    <AutomationCta />
                </div>
            </div>
        </ErrorBoundary>
    )
})