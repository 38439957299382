import { Button, CircularProgress, LinearProgress } from "@mui/material";
import { store } from "../../stores/Store";
import React from "react";
import Ajax from "../../Ajax";
import { Alert } from "../..";
import { NodeReference } from "../../models/Interfaces";
import { Icons, TypeIcon } from "../../misc/Icons";


export default function NodeMoveForm ({accountUid, node}) {
    
    const {getDisplayType, navigateNode} = store.NodeStore
    const [loading, setLoading] = React.useState(null)
    const [breadcrumb, setBreadcrumb] = React.useState<NodeReference[]>([])
    const [currentNode, setCurrentNode] = React.useState(null)

    React.useEffect(() => {
        if (store.NodeStore.folderNode?.uid === accountUid) {
            setCurrentNode(store.NodeStore.folderNode)
            handleBreadcrumbChange(store.NodeStore.folderNode)
        } else {
            navigateOption(accountUid)
        }
    }, [])

    const handleBreadcrumbChange = async (node) => {
        var newBreadcrumb = []
        var nodeInBreadcrumb = false
        
        for (var i = 0; i < breadcrumb.length; i+=1) {
            var item = breadcrumb[i]
            newBreadcrumb.push(item)
            if (item.uid === node.uid) {
                nodeInBreadcrumb = true
                break
            }
        }
        
        if (nodeInBreadcrumb) {
            setBreadcrumb(newBreadcrumb)
        } else {
            setBreadcrumb([...breadcrumb, {uid: node.uid, name: node.label}])
        }
    }

    const navigateOption = async (uid) => {
        setLoading(true)

        await Ajax.Node.Get(uid).then((response) => {
            setCurrentNode(response.data)
            handleBreadcrumbChange(response.data)
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Failed to load node"})
        })

        setLoading(false)
    }

    const submit = async (uid) => {
        setLoading(true)

        await Ajax.Node.Move(node.uid, uid).then(() => {
            navigateNode(uid)
            store.AlertStore.Close()
        }).catch((response) => {
            Alert({message: response.data?.detail ? response.data.detail : "Failed to move node"})
        })

        setLoading(false)
    }

    const childrenToShow = currentNode?.childrenObjects?.sort((a,b) => b.uid.localeCompare(a.uid)).filter(child => child.typeName.includes("FolderNode") || child.typeName.includes("CaseNode"))

    return (
        <div className="form node-move-form">
            <div className="header">
                <h2>Move {getDisplayType(node)} ({node.label})</h2>
            </div>
            
            <div className="form-fields">
                <p>Choose a folder or account to move your {getDisplayType(node)} into.</p>

                <div style={{display: "flex", alignItems: "center"}}>
                    {breadcrumb.map((item) => (
                        <>
                            <Button disabled={loading} className="btn btn-sm" onClick={() => navigateOption(item.uid)}>
                                {item.uid === currentNode.uid ? <b>{item.name}</b> : item.name}
                            </Button>
                            <Icons.ChevronRight style={{fontSize: "14px"}} />
                        </>
                    ))}
                </div>



                <div style={{backgroundColor: "whitesmoke"}}>    
                    <div style={{display: "grid", gridTemplateColumns: "20px auto", padding: "3px 6px", background: "#e2e2e2"}}>
                        <small></small>
                        <small>Name</small>
                    </div>

                    <div style={{maxHeight: "300px", overflow: "auto"}}>
                        {childrenToShow?.map((child, i) => {
                            var possibleParent = (
                                (child.uid === node.uid || child.uid === node.parent?.uid) || // Disable node to be moved and its current parent
                                (!child.uid.includes("ACCOUNT") && !child.uid.includes("FOLDER")) // Disable nodes which cannot be parents
                            )
                            return (
                                <div key={i} style={{display: "grid", gridTemplateColumns: "20px auto", alignItems: "center", padding: "3px 6px", borderTop: "1px solid #e2e2e2"}}>
                                    <TypeIcon node={child} style={{fontSize: "16px", color: "grey"}} />
                                    <Button 
                                        disabled={possibleParent || loading}
                                        style={{padding: "0 !important", textDecoration: "underline", lineHeight: "16px"}}
                                        onClick={() => navigateOption(child.uid)}>
                                        {child.label}
                                    </Button>
                                    
                                    {/* <div>{getDisplayType(child)}</div> */}
                                </div>
                        )})}
                    </div>
                    
                    {loading ? <LinearProgress /> : null}
                </div>
            </div>

            <div className="form-buttons">
                <Button
                    className="btn btn-sm" 
                    onClick={() => store.AlertStore.Close()}>
                    Cancel
                </Button>

                <Button
                    className="btn btn-sm" 
                    variant="contained"
                    color="success"
                    disabled={loading}
                    onClick={() => submit(currentNode.uid)}>
                    Move here
                </Button>
            </div>
        </div>
    )
}



function MoveOption ({node, nodeRefTree, onClick}) {

    var disabled = (
        (nodeRefTree.uid === node.uid || nodeRefTree.uid === node.parent?.uid) || // Disable node to be moved and its current parent
        (!nodeRefTree.uid.includes("ACCOUNT") && !nodeRefTree.uid.includes("FOLDER")) // Disable nodes which cannot be parents
    )

    return (
        <div>
            <Button 
                disabled={disabled} 
                className="node-move-option" 
                onClick={() => {
                    onClick({uid: nodeRefTree.uid, name: nodeRefTree.name})
                }}>
                    <TypeIcon node={nodeRefTree} />
                    <label>{nodeRefTree.name}</label>
            </Button>


            <div style={{marginLeft: "30px"}}>

                {(nodeRefTree.uid.includes("ACCOUNT") || nodeRefTree.uid.includes("FOLDER")) && nodeRefTree.children?.length <= 1 ? (
                    <div className="link font-xs">Load more options</div>
                ) : null}

                {nodeRefTree.children?.map((child, i) => (
                    <MoveOption key={i} node={node} nodeRefTree={child} onClick={(childRef) => onClick(childRef)} />
                ))}
            </div>
        </div>
    )
}