import { observer } from "mobx-react-lite"
import { Icons } from "../../../../misc/Icons"
import { Theme } from "../../../../misc/Theme"
import { Button } from "@mui/material"
import FormAutomationChart from "../../../../misc/Charts"
import { AlertModal } from "../../../.."
import AutomationDetail from "./AutomationDetail"
import Help from "../../../../misc/Help"
import { format } from "date-fns"
import React from "react"
import TruncText from "../../../../misc/TruncText"


export interface AutomationProps {
    automationNode: any;
}


export default observer(function Automation ({automationNode}: AutomationProps) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    const pagesCompletePercent = (outputData.current_page / outputData.total_pages) * 100
    const finishingPagePercentage = (outputData.finishing_page_index / outputData.total_pages) * 100

    return (
        <div className="form-automation">

            <Help content={(
                <div className="form-automation-page-bar">
                    <div className="indicator" style={{width: `${pagesCompletePercent}%`}}></div>
                    <div className="page-limit-indicator" style={{marginLeft: `${finishingPagePercentage-2}%`}}></div>
                </div>
            )}>
                <small>Pages filled: {outputData.current_page + 1} out of {outputData.total_pages}</small>
            </Help>

            <div style={{padding: "15px"}}>
                <div className="form-automation-header">
                    <div style={{display: "flex", alignItems: "center", gap: "10px"}}>
                        <Icons.Automation color="primary" />
                        <h5>{automationNode.label}</h5>
                    </div>

                    <AutomationStatus automationNode={automationNode} />
                </div>

                <div className="form-automation-details">
                    <ResumeDetails automationNode={automationNode} />

                    <ReviewDetails automationNode={automationNode} />
                </div>


                {!automationNode.valid ? (
                    <div className="warning">This application is no longer valid because the AORA enquiry data has changed since the form was automated.</div>
                ) : null}
            </div>
        </div>
    )
})



function ReviewDetails ({automationNode}) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)
    
    var genuineColor = Theme.palette.success.main
    var guessedColor = Theme.palette.warning.main
    var falseColor = Theme.palette.error.main

    return (
        <div className="chart">
            <div style={{display: "grid", gridTemplateColumns: "10px auto 20px", gap: "8px", alignItems: "center"}}>
                <div><div style={{backgroundColor: genuineColor, padding: "5px", borderRadius: "10px"}}></div></div>
                <div>Complete</div>
                <div>{outputData.genuine_value_count}</div>
            
                <div><div style={{backgroundColor: guessedColor, padding: "5px", borderRadius: "10px"}}></div></div>
                <div>Needs checking</div>
                <div>{outputData.guessed_value_count}</div>
            
                <div><div style={{backgroundColor: falseColor, padding: "5px", borderRadius: "10px"}}></div></div>
                <div>Needs updating</div>
                <div>{outputData.false_value_count}</div>
            
                <div></div>
                <div>Total fields</div>
                <div>{outputData.total_fields}</div>

                <Button 
                    className="btn btn-sm"
                    variant="contained" 
                    color="info" 
                    onClick={() => AlertModal({body: <AutomationDetail automationNode={automationNode} />, width: "800px"})}>
                    View details
                </Button>
            </div>

            <FormAutomationChart
                labels={["Genuine", "Empty", "Fake"]}
                chartData={[
                    outputData.genuine_value_count,
                    outputData.guessed_value_count,
                    outputData.false_value_count
                ]}
                colors={[
                    genuineColor,
                    guessedColor,
                    falseColor,
                ]}
            />
        </div>
    )
}



function ResumeDetails ({automationNode}) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    const [revealPassword, setRevealPassword] = React.useState(false)

    if (outputData.profile?.id_check_document_type) {
        return (
            <div>
                <div className="font-xs">Date of birth:</div>
                <div style={{margin: "0 0 5px 5px"}}>{outputData.profile?.id_check_document_date_of_birth}</div>
                
                <div className="font-xs">Document type:</div>
                <div style={{margin: "0 0 5px 5px"}}>
                    <TruncText text={outputData.profile?.id_check_document_type} />
                </div>
            
                <div className="font-xs">Document number:</div>
                <div style={{margin: "0 0 5px 5px"}}>{outputData.profile?.id_check_document_number}</div>

                <Button
                    className="btn btn-sm"
                    variant="contained"
                    onClick={() => window.open("https://apply-to-visit-or-stay-in-the-uk.homeoffice.gov.uk/dashboard")}>
                    Resume
                </Button>
            </div>
        )
    }
    
    if (outputData.resume_link) {
        return (
            <div style={{display: "flex", justifyContent: "space-between", flexDirection: "column"}}>
                <div>
                    <div className="font-xs">Email:</div>
                    <div style={{margin: "0 0 5px 5px"}}><TruncText text={outputData.profile?.advisor_email} /></div>
                    <div className="font-xs">Password:</div>
                    <div style={{margin: "0 0 5px 5px"}}>
                        {revealPassword ? (
                            <div style={{display: "flex", gap: "5px"}}>
                                <span className="code font-grey">{outputData.profile?.password}</span>
                                <Button 
                                    className="btn btn-xs" 
                                    onClick={() => navigator.clipboard.writeText(outputData.profile?.password)}>
                                    Copy
                                </Button>
                            </div>
                        ) : (
                            <Button 
                                className="btn btn-sm" 
                                variant="contained" 
                                color="info" 
                                onClick={() => setRevealPassword(!revealPassword)}>
                                {revealPassword ? "Hide" : "Show"} password
                            </Button>
                        )}
                    </div>
                </div>

                <div style={{margin: "10px 5px 0"}}>
                    <Button
                        className="btn btn-sm"
                        variant="contained"
                        // disabled={!automationNode.valid}
                        onClick={() => window.open(outputData.resume_link)}>
                        Resume
                    </Button>
                </div>
            </div>
        )
    }

    return <p>No resume options</p>
}




function AutomationStatus ({automationNode}) {

    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    var label = "Unknown status"
    var toolTip = <small>Something unexpected happened: {outputData.status}</small>
    var color = Theme.palette.error.main

    switch (outputData.status) {
        case "FINISHED":
            label = "Finished"
            var toolTip = <small>Click 'Resume' to pick up where AORA left off.</small>
            color = Theme.palette.success.main
            break

        case "VALIDATION_ERROR":
            label = "Invalid data"
            toolTip = (
                <div>
                    <h4>Invalid data</h4>
                    <small>Some of your input data failed validation in the online form.</small>
                    {outputData?.validation_messages?.length ? (
                        <ul>
                            {outputData?.validation_messages.map((message, i) => (
                                <li key={i}><small>{message}</small></li>
                            ))}
                        </ul>
                    ) : null}
                </div>
            )
            break
        
        case "HOME_OFFICE_ERROR":
            label = "Gov Website Error"
            toolTip = <small>The government website is having some problems at the moment, please try again later."</small>
            break

        case "MAPPING_ERROR":
            label = "Automation Error"
            toolTip = <small>There was an unexpected page which our software could not automate.</small>
            break
    }

    return (
        <Help content={(
            <div className="automation-status">
                <h5 style={{color: color}}>{label}</h5>
                <small>{format(new Date(automationNode.runDt), "dd/MM/yyyy HH:mm")}</small>
            </div>
        )}>
            {toolTip}
        </Help>
    )
}