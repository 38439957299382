import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import { Button } from "@mui/material";
import CreateAutomationForm from "../../../../form/web_automation/CreateAutomationForm";
import { AlertModal } from "../../../../..";


export default observer(function AutomationCta () {

    var { enquiry } = store.EnquiryStore

    if (enquiry.status !== "DEFINITIVE") return <></>

    var provision = store.EnquiryStore.getQualifyingProvision()
    var onlineForms = store.EnquiryStore.getQualifyingOnlineForms(provision?.rule_id)

    if (!provision || !onlineForms?.length) return <></>

    // Available form
    return (
        <div className="detail-section-clear">
            <div className="section-subheader">
                <small>We have found {onlineForms?.length > 1 ? `${onlineForms.length} online forms which are` : "an online form which is"} relevant to this enquiry.</small>
            </div>

            {onlineForms.map((onlineForm, i) => {
                if (onlineForm.automated) {
                    return (
                        <div key={i} style={{margin: "10px 10px 20px 10px"}}>
                            <small style={{display: "list-item"}}>{onlineForm.name}</small>
                            <a href={onlineForm.link} target="_blank" rel="noreferrer" className="link font-sm">{onlineForm.link}</a>
                            <Button
                                color="success"
                                variant="contained"
                                // disabled={store.AutomationStore.loading}
                                style={{margin: "5px 0 10px"}}
                                onClick={() => AlertModal({width: "700px", body: <CreateAutomationForm onlineForm={onlineForm} />})}
                                className="btn btn-md">
                                Automate this form
                            </Button>
                        </div>
                    )
                }
            
                return (
                    <div key={i} style={{margin: "10px 10px 20px 10px"}}>
                        <small style={{display: "list-item"}}>{onlineForm.name}</small>
                        <a href={onlineForm.link} target="_blank" rel="noreferrer" className="link font-sm">{onlineForm.link}</a>
                    </div>
                )
            })}
        </div>
    )
})