import ErrorDetail from "./ErrorDetail"
import EntityDetail from "./EntityDetail"
import Panel from "../../../layout/Panel"
import { observer } from "mobx-react-lite"
import EnquiryDetail from "./EnquiryDetail"
import SponsorDetail from "./SponsorDetail"
import { store } from "../../../../stores/Store"
import { CircularProgress } from "@mui/material"
import PanelBody from "../../../layout/PanelBody"
import PlaceholderDetail from "./PlaceholderDetail"
import PanelLabel from "../../../layout/PanelLabel"
import PanelHeader from "../../../layout/PanelHeader"


export interface DetailPanelProps {
    index: number;
}


export default observer(function DetailPanel () {  

    const {AppStore, NodeStore, QuestionStore} = store

    const node = NodeStore.leafNode
    const index = QuestionStore.showQuestionPanel ? 2 : 1

    if (NodeStore.loadingLeafNode) {
        return (
            <Panel index={index} id="DETAIL" className="detail" width="100%" loadingContent={NodeStore.loadingLeafNode}>
                <PanelLabel title={<CircularProgress style={{width: "10px", height: "10px", margin: 0}} />} />
                <PanelHeader></PanelHeader>
                <PanelBody></PanelBody>
            </Panel>
        )
    }

    if (!node) {
        return <PlaceholderDetail />
    }

    // return <h5 style={{position: "absolute", top: 0, left: 0}}>Yes</h5>

    switch (node.typeName)
    {
        case "Aora.Nationality.Data.SponsorNode":
            return <SponsorDetail index={index} />
        case "Aora.Nationality.Data.PersonNode":
            return <EntityDetail index={index} />
        case "Aora.Platform.Data.EnquiryNode":
            return <EnquiryDetail index={index} />
        default:
            return <ErrorDetail index={index} />
    }       
})