import { store } from "../../stores/Store"
import NodeMenus from "../../misc/NodeMenus"
import { Icons, TypeIcon } from "../../misc/Icons"
import { PanelChildProps } from "./Panel";
import { Link } from "react-router-dom";
import TruncText from "../../misc/TruncText";
import { ReactElement } from "react";
import PlaceholderComponent from "../../misc/PlaceholderComponent";

interface PanelHeaderProps extends PanelChildProps {
    title?: string | ReactElement;
    showBackLink?: boolean;
}

export default function PanelHeader (props: PanelHeaderProps) {

    const generateClasses = () => {
        var classes = "panel-header"
        classes = props.className ? classes.concat(" "+props.className) : classes
        classes = props.hidden || props.minimised ? classes.concat(" panel-child-hidden") : classes
        return classes
    }

    const Wrapper = ({children}) => {
        return (
            <div className={generateClasses()} aria-hidden={props.minimised} tabIndex={props.minimised ? -1 : null}>
                <div className="panel-header-content">
                    {children}
                </div>
            </div>
        )
    }

    if (props.loadingContent) return <Wrapper><PlaceholderComponent elements={3} /></Wrapper>

    if (!props.node) return <Wrapper>{props.children}</Wrapper>

    var label = props.title 
        ? props.title 
        : store.NodeStore.getDisplayName(props.node)
    
    var breadcrumbPrior = store.NodeStore.breadcrumb.at(-2)
    if (!breadcrumbPrior && store.NodeStore.folderNode) {
        breadcrumbPrior = {uid: "", name: "Home"}
    }

    return (
        <Wrapper>
            <div style={{width: "100%", height: "100%", display: "flex", flexDirection: "column", gap: "7px"}}>
                
                <div style={{width: "100%", display: "flex", justifyContent: "space-between", gap: "20px"}}>
                    <small className="panel-header-node-type"><TypeIcon node={props.node} /> {store.NodeStore.getDisplayType(props.node)}</small>
                    
                    {!store.AppStore.sessionState && props.showBackLink && breadcrumbPrior ? (
                        <TruncText text={(
                            <Link className="link font-sm" to={`/app/main/${breadcrumbPrior.uid}`}>
                                <Icons.ChevronLeft style={{fontSize: "12px"}} /> 
                                Back to {breadcrumbPrior.name}
                            </Link>
                        )} />
                    ) : null}
                </div>

                <div style={{width: "100%", display: "flex", justifyContent: "space-between", alignItems: "end", gap: "20px"}}>
                    <h3 className="trunc-ellipsis">{label}</h3>
                    
                    {props.children}
                    
                    <div style={{marginLeft: "auto"}}>
                        <NodeMenus node={props.node} />
                    </div>
                </div>
                
            </div>
        </Wrapper>
    )
}