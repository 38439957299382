import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import { Button, CircularProgress } from "@mui/material";
import ReportRequestForm from "../../../../form/report/ReportRequestForm";
import { Alert, AlertModal } from "../../../../..";
import Ajax from "../../../../../Ajax";
import React from "react";


export default observer(function ReportCta () {

    const [loading, setLoading] = React.useState(false)
    const { enquiry } = store.EnquiryStore
    const { openReportRequestForm } = store.ReportStore

    if (enquiry.status !== "DEFINITIVE") return <></>

    return (
        <div className="detail-section-clear">
            <div className="section-subheader">
                <small>You may have some report options.</small>
            </div>

            <Button
                color="success"
                className="btn btn-md"
                variant="contained"
                style={{margin: "8px"}}
                disabled={loading}
                onClick={async () => {
                    setLoading(true)
                    await openReportRequestForm().finally(() => setLoading(false))
                }}>
                {loading ? <CircularProgress /> : "Create reports"}
            </Button>
        </div>
    )
})