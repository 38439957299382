import { observer } from "mobx-react-lite";
import { store } from "../../../../../stores/Store";
import { Button } from "@mui/material";


export default observer(function EnquiryCta () {
    
    var { enquiryCtaInfo, enquiry } = store.EnquiryStore
    
    var statusesSection = enquiry?.childrenObjects?.find(c => c.name === "STATUSES")
    var positiveStatus = statusesSection?.childrenObjects?.find(s => s.selected && s.value === "Y")

    const cta = enquiryCtaInfo()

    if (!store.AppStore.sessionState && enquiry.status === "DEFINITIVE") {
        
        var message = !!positiveStatus 
            ? "The enquiry has reached a positive outcome."
            : "The outcome of the enquiry was negative. Please check over the data before informing your client of the outcome."
        
            return (
            <div className="detail-section-clear">
                <div className="section-subheader" style={{border: "none"}}>
                    <small>{message}</small>
                </div>
            </div>
        )
    }
    
    if (!cta) return <></>

    return (
        <div className="detail-section-clear">
            {cta.helpText ? (
                <div className="section-subheader">
                    <small>{cta.helpText}</small>
                </div>
            ) : null}

            <Button
                color={cta.color ? cta.color : "success"}
                className="btn btn-md"
                variant="contained"
                style={{margin: "8px"}}
                onClick={() => cta.action()}>
                {cta.buttonText}
            </Button>
        </div>
    )
})