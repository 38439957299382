import { AlertModal } from "../../../.."
import { Icons } from "../../../../misc/Icons"
import InlineMenu from "../../../../misc/InlineMenu"
import { AutomationProps } from "./Automation"


export default function AutomationOptions ({automationNode}: AutomationProps) {
    
    var outputData = automationNode.serialisedInstance
    outputData = JSON.parse(outputData)

    return (
        <InlineMenu title="Automation options" opener={<span style={{color: "whitesmoke"}}>...</span>} menuItems={[
            // {label: "Re-run", action: () => run(automationNode.automationId)},
            // {label: "Re-run with additional data", action: () => AlertModal({body: <RerunWithAditionalData automationId={automationNode.automationId} />})},
            {
                label: "View log", 
                // hidden: !store.AppStore.checkJwtRole("sysuser"), 
                action: () => {
                AlertModal({size: "xl", body: (
                    <div style={{whiteSpace: "pre", fontFamily: "monospace"}}>
                        <h2>Automation log</h2>
                        <div style={{maxHeight: "70vh", padding: "0 8px", background: "black", color: "#26d126", overflow: "auto"}}>
                            {outputData.log.map((message, i) => <p key={i}>{message}</p>)}
                        </div>
                    </div>
                )})
            }},
            {
                label: "View input data", 
                // hidden: !store.AppStore.checkJwtRole("sysuser"), 
                action: () => {
                AlertModal({body: JSON.stringify(outputData.profile, null, "\t"), code: true, size: "xl"})
            }},
            {
                label: "View missing field keys", 
                // hidden: !store.AppStore.checkJwtRole("sysuser"), 
                action: () => {
                AlertModal({body: JSON.stringify(outputData.non_genuine_fields, null, "\t"), code: true, size: "xl"})
            }},
            {
                label: "View all raw data", 
                // hidden: !store.AppStore.checkJwtRole("sysuser"), 
                action: () => {
                AlertModal({body: JSON.stringify(outputData, null, "\t"), code: true, size: "xl"})
            }}
        ]} />
    )
}