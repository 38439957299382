import Ajax from '../Ajax'
import { store } from './Store'
import { makeAutoObservable } from 'mobx'
import { InformationRequest } from '../models/InformationRequest'


export class QuestionStore {
    
    informationRequest: InformationRequest | null = null;
    initialisationForm: "CREATE_PERSON" | null = null
    serverValidationMessage: string = "";
    provisionCount: number = 0;
    loading: boolean = true;

    constructor() {
        makeAutoObservable(this)
    }

    get showQuestionPanel (): boolean {
        var stateLowerCase = store.AppStore.sessionState?.toLowerCase()

        return (
            !!this.informationRequest ||
            stateLowerCase?.includes("running") ||
            stateLowerCase?.includes("creating person") ||
            (stateLowerCase?.includes("editing") && !!this.informationRequest) ||
            !!this.initialisationForm
        )
    }

    reset = () => {
        this.informationRequest = null
        this.initialisationForm = null
        this.serverValidationMessage = ""
        this.provisionCount = 0
        this.loading = true
    }

    initForm = (initialisationForm: "CREATE_PERSON" | null) => {
        
        this.initialisationForm = initialisationForm
        
        if (initialisationForm !== null) {
            store.AppStore.currentPanel = "QUESTION"
        }
    }

    handleRunInfo = (info: string) => {
        this.provisionCount = this.provisionCount + parseInt(info)
    }

    receiveInformationRequest = async (question: InformationRequest) => {

        this.initForm(null)
        this.serverValidationMessage = ""
        this.informationRequest = new InformationRequest(question)
        this.loading = false

        if (question.locatorPath?.nodeRefs?.length > 0) {
            
            var personUid = question.locatorPath.nodeRefs.at(0).uid // Should always be to the subject of the question
            var sectionUid = question.locatorPath.nodeRefs.at(-1).uid
            
            console.log("Navigate to question subject", (personUid && personUid?.split("_").at(-1) !== "0" && window.innerWidth > 600))

            // Navigate to question subject if there is a valid UID            
            if (personUid && personUid?.split("_").at(-1) !== "0" && window.innerWidth > 600) {
                await store.NodeStore.navigateNode(personUid)
            }
            
            // Highlight section
            if (sectionUid !== personUid) {
                store.NodeStore.highlightSection(sectionUid)
            }
        }

        if (!store.NodeStore.loading && store.AppStore.stateAction?.includes("Running")) {
            // await store.NodeStore.refreshFolderNode()
        }

        store.AppStore.currentPanel = "QUESTION"
        this.provisionCount = 0
    }

    submitAnswer = async (values, certainties) => {

        this.loading = true
        this.informationRequest = null
        this.serverValidationMessage = ""
        store.NodeStore.highlightSectionUid = ""

        await Ajax.Message.Respond(values, certainties).catch((response) => {
            this.serverValidationMessage = response.data.title
        })
    }
}