import Ajax from '../Ajax'
import { Alert } from '..'
import { store } from './Store'
import { makeAutoObservable } from 'mobx'


export class AutomationStore {

    pendingAutomations: any[] = []

    constructor () {
        makeAutoObservable(this)
    }

    get enquiryAutomations () {
        
        var automations = []

        if (store.EnquiryStore.enquiry) {
            var rSection = store.EnquiryStore.enquiry?.childrenObjects?.find(section => section.name === "AUTOMATION_RESULTS")
            if (rSection) {
                automations = rSection.childrenObjects
            }
        }

        // Order by runDt
        automations = [...automations].sort((a, b) => {
            // console.log({...a})
            return new Date(b.runDt).getTime() - new Date(a.runDt).getTime()
        })

        // Then remove any old versions for each automationId
        var existingIds = []
        var trimmedList = []

        automations.forEach(a => {
            if (!existingIds.includes(a.automationId)) {
                trimmedList.push(a)
                existingIds.push(a.automationId)
            }
        })

        return trimmedList
    }

    reset = () => {
        this.pendingAutomations.forEach(automation => {
            automation.dismiss()
        })
    }

    run = async (automationId, additionalData = "{}") => {
        await Ajax.Automation.Run(store.EnquiryStore.enquiry.uid, automationId, additionalData).catch((response) => {
            Alert({message: response.data?.detail})
        })
    }

    handleAutomationNotification = (message) => {

        console.log("AutomationNotification", message)
        
        var { automationUid, enquiryUid, messageText, status } = message
        var { leafNode, refreshLeafNode, navigateNode } = store.NodeStore

        var existingAlert = this.pendingAutomations.find(a => a.enquiryUid === enquiryUid && a.automationUid === automationUid)

        switch (status) {
            case "RUNNING":
                if (!existingAlert) {
                    var alert = Alert({message: messageText, persist: true, closable: false})
                    this.pendingAutomations.push({...message, alert: alert})
                }
                return
            case "COMPLETE":
            case "FAIL":
                if (leafNode.uid === enquiryUid) {
                    refreshLeafNode()
                }
                
                // Close existing alert
                console.log(message, [...this.pendingAutomations.map(e => {return {...e}})])
                
                if (existingAlert) {
                    existingAlert?.alert?.dismiss()
                    var newList = this.pendingAutomations.filter(a => !(a.enquiryUid === enquiryUid && a.automationUid === automationUid))
                    console.log("New list", newList)
                    this.pendingAutomations = newList
                } else {
                    console.log("No alert!")
                }
                
                Alert({
                    message: messageText, 
                    persist: true, 
                    color: status === "COMPLETE" ? "success" : "info",
                    actions: leafNode?.uid === enquiryUid
                        ? []
                        : [{label: "See results", color: "info", action: () => navigateNode(enquiryUid)}] 
                })
                return
            default:
                return
        }
    }
}