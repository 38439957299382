import { Button, CircularProgress } from "@mui/material"
import Help from "../../../../misc/Help"
import { Theme } from "../../../../misc/Theme"
import { store } from "../../../../stores/Store"
import { AlertModal, DisplayValue } from "../../../.."
import EnquiryUpdateSectionForm from "../../../form/enquiry/EnquiryUpdateSectionForm"
import { observer } from "mobx-react-lite"
import { Enquiry } from "../../../../models/Enquiry"
import { format } from "date-fns"
import { Status } from "./EnquiryStatusSection"
import React from "react"


interface EnquirySectionProps {
    enquiry: Enquiry;
    editable?: boolean;
    showCertainty?: boolean;
    maxHeight?: string;
}


export default observer(function EnquiryStatusSection ({enquiry, editable = true, showCertainty = true, maxHeight = "none"}: EnquirySectionProps) {

    var statusesSection = enquiry?.childrenObjects?.find(c => c.name === "STATUSES")
    var statuses = statusesSection?.childrenObjects
    var sections = []

    // Restructure to list of sections
    statuses.filter(s => s.selected).forEach(status => {

        var existingSection = sections.find(s => s.type === status.type)

        if (existingSection) {
            existingSection.rows.push(status)
        } else {
            sections.push({
                label: status.type,
                type: status.type,
                rows: [status]
            })
        }
    })

    return (
        <>
            <div className="section-header">
                <div className="header-content">
                    <h4>Details</h4>
                </div>
            </div>
            <div className="detail-section" style={{overflowY: "auto", maxHeight: maxHeight}}>
                {sections.map((section, i) => (
                    <React.Fragment key={i}>
                        {sections.length > 1 ? (
                            <div className="section-header section-subheader">
                                <h5>{DisplayValue("nationality_type", section.label)}</h5>
                            </div>
                        ) : null}
                        
                        <div className="section-table">
                            {section.rows.filter(s => s.selected === true).map((status, i) => <Status key={i} status={status} enquiryStatus={enquiry.status} showCertainty={showCertainty} />)}
                        </div>
                    </React.Fragment>
                ))}

                <div className="section-header section-subheader">
                    <h5>Basic details</h5>
                </div>

                <div className="section-table">
                    {enquiry.subjects.map((subject, i) => (
                        <div key={i} className="section-row attribute-row">
                            <div className="section-cell">Subject</div>
                            <div className="section-cell link" onClick={() => store.NodeStore.navigateNode(subject.uid)}>{subject.name}</div>
                        </div>
                    ))}

                    <div className="section-row attribute-row">
                        <div className="section-cell">Created at</div>
                        <div className="section-cell">
                            {format(new Date(enquiry.created), "dd/MM/yyyy H:mm")}
                        </div>
                    </div>

                    <div className="section-row attribute-row">
                        <div className="section-cell">Last run</div>
                        <div className="section-cell">
                            {format(new Date(enquiry.lastStarted), "dd/MM/yyyy H:mm")}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
})