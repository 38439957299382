import Ajax from "../../Ajax";
import { format } from "date-fns";
import { Alert, CloseModal } from "../..";
import { store } from "../../stores/Store";
import { observer } from "mobx-react-lite";
import { Button, CircularProgress } from "@mui/material";
import EnquiryStatusSection from "../views/main/detail/EnquiryStatusSection";


export default observer(function EnquiryOutcomeModal () {

    const {loadingLeafNode} = store.NodeStore
    const {enquiry, getSubject} = store.EnquiryStore

    if (loadingLeafNode || !enquiry) {
        return (
            <div style={{display: "flex", alignItems: "center"}}>
                <CircularProgress style={{width: "16px", height: "16px", margin: "10px"}} />
                <h4>Generating outcome...</h4>
            </div>
        )
    }

    var restricted = store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false)

    switch (enquiry.status) {

        case "FINAL":
        case "INTERIM":
            
            return (
                <div className="form">
                    <h2>Interim result</h2>
                    <p>We have reached an outcome for {getSubject(enquiry)?.name} under the law of the {enquiry.ruleSystem}.</p>
                    <p>There is still some information needed to complete the full enquiry and generate reports, choose to continue below.</p>
                    
                    <EnquiryStatusSection enquiry={enquiry} editable={false} showCertainty={false} maxHeight="400px" />
                    
                    <div className="form-buttons">
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        <Button 
                            className="btn btn-sm" 
                            variant="contained" 
                            onClick={() => CloseModal().then(() => Ajax.Enquiry.Run(enquiry.uid))}>
                            Complete now
                        </Button>
                    </div>
                </div>
            )

        case "DEFINITIVE":

            return (
                <div className="form">
                    <h2>Full outcome reached</h2>

                    {!restricted 
                        ? <p>Outcome of determination for {getSubject(enquiry)?.name} under the law of the {enquiry.ruleSystem} on {format(new Date(enquiry.interestDate), "dd LLLL yyyy")}</p>
                        : <p>Thank you for supplying all of the required information</p>}
                    
                    <EnquiryStatusSection enquiry={enquiry} editable={false} maxHeight="400px" />
                    
                    <div className="form-buttons">
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        {!restricted && <Button 
                            className="btn btn-sm" 
                            variant="contained" 
                            onClick={async () => {
                                store.AlertStore.Close()
                                var alert = Alert({message: "Getting report options."})
                                store.ReportStore.openReportRequestForm().finally(() => alert.dismiss())
                            }}>
                            Order reports
                        </Button>}
                    </div>
                </div>
            )

        case "RERUN_REQUIRED":
            return (
                <div className="form">
                    <h2>A re-run is required</h2>
                    <p>
                        An outcome of determination for {getSubject(enquiry)?.name} under the law of the {enquiry.ruleSystem} had previously been reached, 
                        however, subsequent changes in the relevant data have made the result invalid and requires you to re-run the enquiry.
                    </p>
                    
                    <EnquiryStatusSection enquiry={enquiry} editable={false} maxHeight="400px" />
                    
                    <div className="form-buttons">
                        <Button className="btn btn-sm" onClick={() => CloseModal()}>Close</Button>
                        <Button 
                            className="btn btn-sm" 
                            variant="contained" 
                            onClick={() => CloseModal().then(() => Ajax.Enquiry.Run(enquiry.uid))}>
                            Re-run
                        </Button>
                    </div>
                </div>
            )

        default:
            return (
                <div className="form">
                    <h2>No outcome reached</h2>
                    <p>Continue running the enquiry in order to reach an outcome</p>
                </div>
            )
    }

})