import { Alert } from '..'
import Ajax from '../Ajax'
import { store } from './Store'
import { history } from '../index'
import { makeAutoObservable } from 'mobx'
import { ErrorNode, NodeReference } from '../models/Interfaces';
import { AxiosResponse } from 'axios'
import Account from '../models/Account'


export class NodeStore {

    folderNode: any | null;
    leafNode: any | null;
    accounts: Account[];
    accountUid: string;
    loadingLeafNode: boolean;
    loading: boolean;
    breadcrumb: NodeReference[];
    isEditing: boolean;
    highlightSectionUid: string;

    constructor() {
        this.reset()
        makeAutoObservable(this)
    }

    reset = () => {
        this.folderNode = null
        this.leafNode = null
        this.accounts = []
        this.accountUid = null
        this.loadingLeafNode = true
        this.loading = true
        this.breadcrumb = []
        this.isEditing = false
        this.highlightSectionUid = null
    }

    get account() {
        return this.accounts?.find(a => a.uid === this.accountUid)
    }

    get rootNodeUid() {
        return store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false) ? store.AppStore.sessionInfo.userUid?.replace("USER", "FOLDER") : store.AppStore.sessionInfo.parentAccount.uid
    }

    get getFolderChildren () {

        if (!this.folderNode) {

            var userAccountUid = store.AppStore.sessionInfo.parentAccount?.uid
            var sysadmin = userAccountUid === "ACCOUNT_0"

            return [
                ...this.accounts.filter(account => userAccountUid === account?.uid), // Always have advisor account first
                ...this.accounts.filter(account => account.accountType === "sponsor"),
                ...this.accounts.filter(account => account.accountType === "client")
            ]
        }

        if (!this.folderNode.childrenObjects?.length) return []

        // First, sort alphabetically
        var children = [...this.folderNode.childrenObjects].sort((a, b) => a.label?.localeCompare(b.label))

        // Only show folders under accounts, order alphabetically
        if (this.folderNode.typeName?.includes("AccountNode") || this.folderNode.typeName?.includes("FolderNode")) {
            return [
                ...children.filter((child) => ["Aora.Nationality.Data.SponsorNode"].includes(child.typeName)),
                ...children.filter((child) => ["Aora.Platform.Data.FolderNode"].includes(child.typeName)),
                ...children.filter((child) => ["Aora.Platform.Data.CaseNode"].includes(child.typeName))
            ]
        }

        // Order People by date of birth, put sponsor first
        if (this.folderNode.typeName?.includes("CaseNode")) {
            return [
                ...children.filter(child => child.typeName.includes("Sponsor")),
                ...children.filter(child => child.typeName.includes("EnquiryNode")).sort((a, b) => {
                    return (a.subjects?.length && b.subjects?.length) ? a.subjects[0].name?.localeCompare(b.subjects[0].name) : true
                }),
                ...children.filter(child => child.typeName.includes("PersonNode")).sort((a, b) => b.dateOfBirth?.localeCompare(a.dateOfBirth))
            ]
        }

        return children
    }

    setFolderNode = (node: any) => {
        console.log("Setting folder node", node?.uid, node?.label)

        if (node === null) { // null folderNode will show the accounts list
            store.AppStore.currentPanel = "FOLDER"
        }

        this.folderNode = node

        if (node?.typeName.includes("Account") || node === null) {
            this.breadcrumb = []
        }

        this.isEditing = false
    }

    setLeafNode = (node: any) => {
        console.log("Setting leaf node", node?.uid, node?.label)

        this.leafNode = node

        if (node?.typeName === "Aora.Nationality.Data.PersonNode" || node?.typeName === "Aora.Platform.Data.EnquiryNode") {
            if (node.caseNode && !window.location.href.includes(node.caseNode.uid)) {
                this.navigateNode(node.caseNode.uid)
            }
        }
    }

    updateOrEditProcedure = async (action: Function) => {
        try {
            if (store.QuestionStore.informationRequest) {
                throw new Error("You cannot edit two attributes at once")
            }
            if (!store.AppStore.sessionState) {
                await Ajax.Entity.BeginEdit(store.NodeStore.leafNode.uid)
            } else if (store.AppStore.stateSubject !== store.NodeStore.leafNode.uid) {
                throw new Error(`You cannot edit this while ${store.AppStore.sessionState}`)
            }
            await action().then(() => {
                store.NodeStore.refreshLeafNode()
            }).catch((response) => {
                Alert({message: response.data.detail, color: "error"})
            })
        }
        catch (e) {
            Alert({message: e.message})
        }
    }

    refreshFolderNode = () =>
        this.selectNode(this.folderNode ? this.folderNode.uid : this.rootNodeUid)

    refreshLeafNode = () =>
        this.selectNode(this.leafNode.uid)

    refreshAccounts = async () => {

        var accounts = []

        await Ajax.Node.GetAccounts().then((response) => {
            accounts = response.data?.sort((a, b) => a.label.localeCompare(b.label))
        })

        this.accounts = accounts

        if (this.accountUid === null || !accounts.find(account => account.uid === this.accountUid)) {
            this.accountUid = store.AppStore.sessionInfo?.parentAccount?.uid
        }
    }

    navigateNode = async (uid: string = null) => {

        if (!uid || uid === this.rootNodeUid) { // Navigate to home if no args are given
            history.push("/app/main")
        }

        // UID must be in '{type}_{id}' format
        if (uid?.split("_", 2).length < 2) { return }

        var type = uid?.split("_")[0]

        switch (type) {

            case "ACCOUNT":
            case "FOLDER":
            case "CASE":

                if (!this.loading) { // Disable navigation when already loading node
                    var leafNodeUid = this.leafNode?.uid ? this.leafNode?.uid : ""
                    history.push(`/app/main/${uid}/${leafNodeUid}`)
                }
                return

            case "SPONSOR":
            case "USER":
            case "ENTITY":
            case "PERSON":
            case "ENQUIRY":
                if (!this.loadingLeafNode) { // Disable navigation when already loading nodes
                    var folderNodeUid = this.folderNode?.uid ? this.folderNode.uid : this.rootNodeUid
                    history.push(`/app/main/${folderNodeUid}/${uid}`)
                }
                return
        }
    }


    handleUri = async (params: any) => {

        var folderNode = null
        var leafNode = null

        // Handle folder node
        if (!("folder_uid" in params)) {
            this.setFolderNode(null)
            this.loading = false
        } else if ("folder_uid" in params && this.folderNode?.uid !== params.folder_uid) {
            folderNode = await this.selectNode(`${params.folder_uid}`, !!store.AppStore.sessionState !== true)
        }

        // Handle leaf node
        if (!("leafnode_uid" in params)) {
            this.setLeafNode(null)
            this.loadingLeafNode = false
        } else {
            leafNode = await this.selectNode(`${params.leafnode_uid}`, !!store.AppStore.sessionState !== true)
        }
    }


    // All node navigation should be done by changing the URI and then calling selectNode from handleUri
    private selectNode = async (uid: string, focusPanel: boolean = true) => {

        var node: any

        // UID must be in '{type}_{id}' format
        if (uid?.split("_", 2).length < 2) { return }

        var type = uid?.split("_")[0]

        switch (type) {
            case "ACCOUNT":
            case "FOLDER":
            case "CASE":

                this.loading = true
                store.AppStore.currentPanel = "FOLDER"

                if (type === "ACCOUNT") { this.accountUid = uid }

                await Ajax.Node.Get(uid).then((response) => {
                    node = response.data
                }).catch((response) => {
                    node = this.generateErrorNode(uid, response)
                })

                this.setFolderNode(node)
                this.updateBreadcrumb(node)

                store.AppStore.setPageTitle(this.getDisplayName(node))

                this.loading = false
                return Promise.resolve(node)

            case "SPONSOR":
            case "USER":
            case "ENTITY":
            case "PERSON":
            case "ENQUIRY":

                this.loadingLeafNode = true
                store.AppStore.currentPanel = "DETAIL"

                await Ajax.Node.Get(uid).then((response) => {
                    node = response.data
                }).catch((response) => {
                    node = this.generateErrorNode(uid, response)
                })

                this.setLeafNode(node)

                this.loadingLeafNode = false
                return Promise.resolve(node)

            default:
                Alert({ message: `Unexpected type: ${uid}` })
                return Promise.reject()

        }
    }


    // getAncestors = async (node, count = 20): Promise<NodeReferenceTree> => {
        
    //     var tree: NodeReferenceTree = {
    //         uid: node.uid,
    //         name: node.label,
    //         typeName: node.typeName,
    //     }
        
        

    //     if (node.parent) {
    //         await Ajax.Node.Get(node.parent.uid).then((response) => {
    //             tree.parent = {
    //                 uid: response.data.uid,
    //                 name: response.data.label,
    //                 typeName: response.data.typeName,
    //             }
    //         })
    //     }
        
    //     return tree
    // }


    private generateErrorNode = (uid: string, response: AxiosResponse) => {

        var label = "Server error"
        if (response?.status === 404) {
            label = "Not found"
        } else if (response?.status === 403) {
            label = "Unauthorised"
        }

        var node = new ErrorNode({
            uid: uid,
            typeName: "Error",
            label: label,
            detail: response?.data?.detail
        })

        return node
    }


    highlightSection = (sectionUid: string = null) => {
        this.highlightSectionUid = sectionUid
    }


    private updateBreadcrumb = (node) => {

        // if (node.uid.includes("ACCOUNT")) {
        //     this.breadcrumb = []
        //     return
        // }

        var displayName = this.getDisplayName(node)

        // Look for existing reference
        var existingReference = this.breadcrumb.find((bc, i) => bc.uid === node.uid)
        var existingReferenceIndex = this.breadcrumb.indexOf(existingReference)

        this.breadcrumb = existingReference
            ? [...this.breadcrumb.slice(0, existingReferenceIndex), { uid: node.uid, name: displayName }] // Trunc back to existing reference
            : [...this.breadcrumb, { uid: node.uid, name: displayName }] // Append new reference

        // Check if the first reference is to the root node, if not then insert a reference at the start.
        // Once GetNodePath is implemented, improve this so that the whole path to the root node is inserted.
        // if (this.rootNodeUid && this.rootNodeUid !== this.breadcrumb[0].uid) {
        //     this.breadcrumb = [{uid: this.rootNodeUid, name: "Root"}, ...this.breadcrumb]
        // }
    }


    getDisplayName = (node: any) => {

        switch (node?.typeName) {
            case "Error":
            case "Aora.Platform.Data.UserNode":
            case "Aora.Platform.Data.AccountNode":
            case "Aora.Nationality.Data.SponsorAccountNode":
            case "Aora.Nationality.Data.SponsorNode":
            case "Aora.Nationality.Data.PersonNode":
                return node.label
            case "Aora.Platform.Data.FolderNode":
            case "Aora.Platform.Data.CaseNode":
                return node.name
            case "Aora.Platform.Data.EnquiryNode":
                var id = node.uid.split("_").length === 2 ? node.uid.split("_")[1] : "???"
                return "Enquiry " + id
            case undefined:
                return "Node"
            default:
                return node.uid
        }
    }

    getDisplayType = (node: any, plural: boolean = false) => {

        switch (node?.typeName) {
            case "Aora.Platform.Data.AccountNode":
                if (node.uid === "ACCOUNT_0") return "System admin account"
                if (node.uid === store.AppStore.sessionInfo.parentAccount.uid) return "Legal adviser"
                var typeName = "Private Client"
                return plural ? typeName + "s" : typeName

            case "Aora.Nationality.Data.SponsorAccountNode":
                return plural ? "Sponsors" : "Sponsor"

            case "Aora.Nationality.Data.SponsorNode":
                return plural ? "Sponsor details" : "Sponsor details"

            case "Aora.Platform.Data.UserNode":
                return plural ? "Users" : "User"

            case "Aora.Platform.Data.FolderNode":
                return plural ? "Folders" : "Folder"

            case "Aora.Platform.Data.CaseNode":
                return plural ? "Cases" : "Case"

            case "Aora.Nationality.Data.PersonNode":
                return plural ? "People" : "Person"

            case "Aora.Platform.Data.EnquiryNode":
                return plural ? "Enquiries" : "Enquiry"

            case "Aora.Platform.Data.ReportNode":
                return plural ? "Reports" : "Report"

            default:
                return plural ? "Entities" : "Entity"

        }
    }

    scrollToDetailNode = (nodeUid: string) => {

        var detailPanel = document.getElementById("panel-2")
        var scrollEl = detailPanel.getElementsByClassName("panel-body")

        var elements = document.querySelectorAll(`[data-uid='${nodeUid}']`)

        if (elements.length && scrollEl.length) {
            var attrElement = elements[0].getBoundingClientRect();
            scrollEl[0].scrollTo(0, attrElement.top)
            console.log(attrElement.top)
        }
    }

}