import * as Yup from 'yup'
import { useFormik } from "formik"
import Field from "../fields/Field"
import { DisplayValue } from '../../..'
import { FormSectionProps } from '../../../models/Interfaces'


export default function EnquiryStatusesFormSection ({formId, onSubmit, section}: FormSectionProps) {

    var formik = useFormik({

        initialValues: { statuses: [] },

        validationSchema: Yup.object({
            statuses: Yup.array()
                .min(1, `You must select a type of enquiry`)
        }),

        onSubmit: (values) => {
            var newSection = {...section}
            newSection.childrenObjects = section.childrenObjects
            // .filter(status => status.code.slice(-2) !== "_P") // Don't send potential statuses, these can only be set by the backend
            .map(status => {
                status.selected = values.statuses.includes(status.uid)
                return status
            })
            onSubmit(newSection)
        }
    })

    // var swv = section.childrenObjects.find(s => s.code === "UKLLR_SW_P")

    // if (swv) {
    //     section.childrenObjects = [...section.childrenObjects.filter(s => s.code === "UKLLR_SW_P")]
    // }

    // Transform flat list of statuses into a tree structured by type
    var nationalityOptionsNested = []
    var typeOrder = ["POTENTIAL","CITIZEN.PROTÉGÉ","TR_STATUS","PR_STATUS","OTHER"]

    // Add each status to tree
    typeOrder.forEach((type => {

        var statuses = section.childrenObjects.filter(nat => type.includes(nat.type))

        if (type === "CITIZEN.PROTÉGÉ") {
            type = "CITIZEN"
            // statuses.forEach(status => {
            //     status.type = "CITIZEN"
            // })
        }

        if (type === "PR_STATUS") {
            statuses = statuses.reverse()
        }

        if (statuses?.length) {
            nationalityOptionsNested.push({
                label: DisplayValue("nationality_type", type),
                value: type,
                children: statuses.map(status => {
                    return {
                        value: status.uid,
                        label: status.label,
                        // disabled: disabled
                    }
                })
            })
        }
    }))

    return (
        <form id={formId} className="form-fields" onSubmit={formik.handleSubmit}>
            <p>Select the legal statuses you want to investigate</p>
            
            <div style={{maxHeight: "300px", overflow: "auto"}}>
                <Field.CheckboxSelect
                    formik
                    name={"statuses"}
                    options={nationalityOptionsNested}
                    onChange={formik.handleChange}
                    value={formik.values.statuses}
                    warning={null} />
            </div>
                
            {formik.errors.statuses
                ? <div className="form-message warning">{formik.errors.statuses}</div>
                : null}
        </form>
    )
}



