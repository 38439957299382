import Ajax from "../Ajax";
import { Alert, AlertModal } from "..";
import { store } from "../stores/Store";
import { observer } from "mobx-react-lite";
import IsAllowed from "../models/Permissions";
import InlineMenu, { InlineMenuItem } from "./InlineMenu";
import DeleteForm from "../components/form/case/DeleteForm";
import { UserUpdateForm } from "../components/form/admin/UserForms";
import EnquiryOutcomeModal from "../components/modals/EnquiryOutcomeModal";
import CreateEnquiryForm from "../components/form/enquiry/CreateEnquiryForm";
import { CaseCreateForm, CaseEditForm } from "../components/form/case/CaseForms";
import UpdatePasswordForm from "../components/form/admin/UserPasswordUpdateForm";
import EnquiryUpdateSectionForm from "../components/form/enquiry/EnquiryUpdateSectionForm";
import NodeMoveForm from "../components/form/NodeMoveForm";
import { FolderEditForm } from "../components/form/folder/FolderForms";


interface NodeOptionsMenuProps {
    node: any;
    disabled?: boolean;
}


export default observer(function NodeOptionsMenu ({node, disabled = false}: NodeOptionsMenuProps) {

    if (store.AppStore.checkJwtRoles(["client_account", "sponsor_account"], false)) return <></>

    if (!node) {
        return <InlineMenu disabled={true} menuItems={[]} />
    }

    return (
        <InlineMenu
            disabled={!!store.AppStore.sessionState || disabled}
            title={store.NodeStore.getDisplayType(node) + " options"}
            menuItems={NodeOptionsMenuItems(node)} />
    )
})


const NodeOptionsMenuItems = (node): InlineMenuItem[] => {

    const {ReportStore, ToolStore, NodeStore} = store

    switch (node.typeName) {
        case "Aora.Platform.Data.AccountNode":
        case "Aora.Nationality.Data.SponsorAccountNode":

            return [
                {
                    // label: node.typeName === "Aora.Nationality.Data.SponsorAccountNode" ? "Create Skilled Worker Visa case" : "Create nationality case",
                    label: `Create case for ${node.label}`,
                    action: () => {
                        const guidance = node.typeName === "Aora.Nationality.Data.SponsorAccountNode"
                            ? `This case will be associated with ${node.label}, Skilled Worker enquiries will be available.`
                            : "This case won't be associated with a Sponsor Client so skilled worker enquiries will not be possible."
                        AlertModal({body: <CaseCreateForm parentUid={node.uid} guidance={guidance} onSuccess={(node) => {
                            store.AlertStore.Close()
                            store.NodeStore.navigateNode(node.uid)
                        }} />})
                    }
                },
                {label: `Edit info for ${node.label}`,                   action: () => {
                    ToolStore.setAccount(node)
                    ToolStore.setOpen(true)
                    ToolStore.setTool("account")
                    ToolStore.loading = false
                }},
                {label: "Refresh",                          action: () => NodeStore.refreshFolderNode()}
            ]
        case "Aora.Platform.Data.UserNode":
            return [
                {label: "Change password",              action: () => AlertModal({body: <UpdatePasswordForm user={node} />, size: "xs"})},
                {label: "Edit user",                    action: () => AlertModal({body: <UserUpdateForm user={node} onSuccess={() => store.AlertStore.Close()} requestFullUser />}),   hidden: IsAllowed(node, "UPDATE")},
            ]
        case "Aora.Platform.Data.FolderNode":

            return [
                {label: "Edit Folder",                    action: () => AlertModal({body: <FolderEditForm folderNode={node} />}) },
                {label: "Move folder",                   action: () => AlertModal({body: <NodeMoveForm accountUid={node.account.uid} node={node} />, width: "600px"})},
                {label: "Delete folder",                action: () => AlertModal({body: <DeleteForm node={node} />}), hidden: !IsAllowed(node, "DELETE")},
                {label: "Refresh",                      action: () => NodeStore.refreshFolderNode()}
            ]

        case "Aora.Nationality.Data.SponsorNode":
            return [
                {label: "Edit sponsor",                 action: () => { NodeStore.isEditing = true }}
            ]

        case "Aora.Platform.Data.CaseNode":

            var accountUid = null

            if (NodeStore.folderNode.uid.includes("ACCOUNT")) {
                accountUid = NodeStore.folderNode?.uid
            } else if (NodeStore.folderNode.uid.includes("FOLDER")) {
                accountUid = NodeStore.folderNode?.account?.uid
            }

            return [
                {label: "Create person",                action: () => store.QuestionStore.initForm("CREATE_PERSON")},
                {label: "Edit case",                    action: () => AlertModal({body: <CaseEditForm caseNode={node} />}) },
                {label: "Move case",                   action: () => AlertModal({body: <NodeMoveForm accountUid={accountUid} node={node} />, width: "600px"}), hidden: !accountUid},
                {label: "Delete case",                  action: () => AlertModal({body: <DeleteForm node={node} />}), hidden: !IsAllowed(node, "DELETE")},
                {label: "Refresh",                      action: () => NodeStore.refreshFolderNode()}
            ]

        case "Aora.Nationality.Data.PersonNode":
            return [
                {label: "Edit person",                  action: () => { store.NodeStore.isEditing = true }},
                {label: "New enquiry",                  action: () => AlertModal({body: <CreateEnquiryForm entityUid={node.uid} />, size: "auto"})},
            ]

        case "Aora.Platform.Data.EnquiryNode":

            const cta = store.EnquiryStore.enquiryCtaInfo()
            var settingsSection = node.childrenObjects.find(s => s.name === "SETTINGS")
        
            return [
                {label: cta?.buttonText,                        action: () => cta?.action(), hidden: !cta},
                {label: "Re-run",                               action: () => Ajax.Enquiry.Rerun(node.uid).catch(response => Alert({message: response?.data?.detail})), hidden: !store.AppStore.checkJwtRole("sysuser")},

                {label: "View outcome",                         action: () => AlertModal({body: <EnquiryOutcomeModal />, width: "700px"})},
                {label: "Enquiry settings",                             action: () => AlertModal({title: "Update settings", body: <EnquiryUpdateSectionForm enquiryUid={node.uid} section={settingsSection} />})},
            ]

        case "Aora.Platform.Data.ReportNode":
            return [
                {label: "Download",         action: () => ReportStore.download(node),       disabled: ["R", "F"].includes(node.fileStatus)}, // Disabled if "Requested" or "Failed"
                {label: "Regenerate",       action: () => ReportStore.regenerate(node),     hidden: !IsAllowed("ReportNode", "UPDATE") },
                {label: "Mark as invalid",  action: () => ReportStore.makeInvalid(node),    hidden: !IsAllowed("ReportNode", "UPDATE") },
            ]

        default:
            return [
                // {label: "No options",       action: () => {}, disabled: true},
                {label: "Refresh",          action: () => NodeStore.refreshFolderNode()}
            ]
    }
}