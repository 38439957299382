import Ajax from '../Ajax'
import { Alert, AlertModal } from '..'
import { store } from './Store'
import { Theme } from '../misc/Theme'
import { makeAutoObservable } from 'mobx'
import { Enquiry } from '../models/Enquiry';
import { provisions } from '../misc/4C Provisions'
import { onlineForms } from '../misc/Online Forms'
import { CtaInfo } from '../models/Interfaces'
import { Icons } from '../misc/Icons'
import EnquiryResumeForm from '../components/form/enquiry/EnquiryResumeForm'


export class EnquiryStore {

    constructor() {
        makeAutoObservable(this)
    }

    get enquiry () {

        if (store.NodeStore.leafNode?.typeName === "Aora.Platform.Data.EnquiryNode"){
            return store.NodeStore.leafNode
        }

        return null
    }

    get enquiryStatuses () {

        var statuses = this.enquiry?.childrenObjects?.find(s => s.name === "STATUSES")

        if (statuses) {
            return statuses.childrenObjects
        }

        return null
    }

    getQualifyingProvision = () => {

        var statuses = this.enquiry?.childrenObjects?.find(s => s.name === "STATUSES")
        
        if (statuses) {
            var positive_statuses = statuses.childrenObjects.filter(status => status.value === "Y")
            
            for (var s = 0; s < positive_statuses.length; s++) {
                var status = positive_statuses[s]
                
                for (var p = 0; p < provisions.length; p++) {
                    var provision = provisions[p]
                    
                    if (provision.rule_id === status.qualifyingRule) {
                        return provision
                    }
                }
            }
        }

        return null
    }

    getQualifyingOnlineForms = (ruleId) => {

        var reports = store.ReportStore.enquiryReportsCurrentOnly.filter(r => r.valid)
        var ecReport = reports.find(r => r.reportType === "AEC")
        var flrReport = reports.find(r => r.reportType === "AFLR")
        
        var onlineFormsOptions = onlineForms.filter(form => {
            if (ruleId === "UK.D.33.1") {
                if (["SWV_EC", "SWV_EC_PARTNER", "SWV_EC_CHILD"].includes(form.id) && flrReport) {
                    return false
                }
                if (["SWV_FLR", "SWV_FLR_PARTNER", "SWV_FLR_CHILD"].includes(form.id) && ecReport) {
                    return false
                }   
            }

            return form?.rule_id === ruleId
        })

        return onlineFormsOptions
    }

    getSubject (enquiry: Enquiry) {

        if (enquiry?.subjects?.length) {
            return enquiry?.subjects[0]
        }

        return {name: "No subject", uid: ""}
    }

    rerun = () => {
        Ajax.Enquiry.Rerun(this.enquiry.uid)
    }

    setEnquiry (enquiry: Enquiry) {

        store.NodeStore.setLeafNode(enquiry)

        if (enquiry) {
            var rSection = enquiry.childrenObjects.find(section => section.name === "REPORTS")
            if (rSection) {
                store.ReportStore.setReports(rSection.childrenObjects)
            }
        }
    }

    color (enquiry: Enquiry): string {

        var value = Theme.palette.info.contrastText

        if (enquiry?.status === "DEFINITIVE" || enquiry?.status === "FINAL") {
            value = Theme.palette.success.main
        }
        if (enquiry?.status === "RERUN_REQUIRED" || enquiry?.status === "REPORT_DATA_REQUIRED") {
            value = Theme.palette.error.main
        }

        return value
    }

    enquiryCtaInfo = (): CtaInfo => {
        if (!this.enquiry) {
            return null
        }
        if (store.AppStore.sessionState && store.AppStore.stateSubject !== this.enquiry.uid) {
            return null
        }
        if (store.AppStore.sessionState && store.AppStore.stateSubject !== this.enquiry.uid) {
            return {
                buttonText: "Cancel",
                action: () => Ajax.Message.Cancel()
            }
        }

        var title = "Run enquiry"

        switch (this.enquiry.status) {
            case "INTERIM":
                title = "Confirm assumptions"
                return {
                    buttonText: title,
                    helpText: "This enquiry has reached an outcome based on the information you provided and also some assumptions made by AORA. For a definitive outcome you must confirm these assumptions.",
                    action: () => AlertModal({body: <EnquiryResumeForm />, title: title, width: "600px"})
                }
            case "REPORT_DATA_REQUIRED":
                title = "Complete now"
                return {
                    buttonText: title,
                    helpText: "This enquiry has reached a definitive outcome, however, the reports which you requested require some more information.",
                    action: () => AlertModal({body: <EnquiryResumeForm />, title: title, width: "600px"})
                }
            case "RERUN_REQUIRED":
                title = "Rerun enquiry"
                return {
                    buttonText: title,
                    helpText: "This enquiry has reached an outcome, however, the data has since been changed and the enquiry must be rerun.",
                    action: () => AlertModal({body: <EnquiryResumeForm />, title: title, width: "600px"})
                }
            case "INITIAL":
                title = "Resume enquiry"
                return {
                    buttonText: title,
                    helpText: "This enquiry has not yet reached an outcome.",
                    action: () => AlertModal({body: <EnquiryResumeForm />, title: title, width: "600px"})
                }
            default:
                return null
        }
    }

    outcomeColor = (outcomeCode): string => {
        if (outcomeCode === "Y") {
            return Theme.palette.success.main
        } else if (outcomeCode === "N") {
            return Theme.palette.error.main
        }
        return Theme.palette.info.contrastText
    }

    outcomeIcon = (outcomeCode, fontSize = "14px"): React.ReactElement => {
        if (outcomeCode === "Y") {
            return <Icons.Positive style={{fontSize: fontSize}} />
        } else if (outcomeCode === "N") {
            return <Icons.Negative style={{fontSize: fontSize}} />
        }
        return <></>
    }
}